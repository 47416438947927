<template>
  <div>
    <h1 class="text-h4">Управление продажами</h1>
    <v-divider class="my-2"></v-divider>
    <v-form ref="form" v-model="form.valid" :disabled="busy" @submit.prevent="submitForm">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Изменить товар {{ item.code }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu bottom right offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="$router.push({name:'item.view',params:{id:item.id}})">
                <v-list-title>Редактировать через Товары</v-list-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            v-model="form.data.name"
            label="Название"
            counter="150"
            :rules="form.rule.name"
          />
          <v-textarea
            v-model="form.data.content"
            label="Состав"
            counter="1024"
            rows="1"
            auto-grow
            :rules="form.rule.content"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="$router.back()">Назад</v-btn>
          <v-btn color="primary" :disabled="!form.valid" type="submit">Отправить</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <v-card class="mt-2">
      <v-card-title>Изображения</v-card-title>
      <v-card-text>
        <div v-if="item.images.length>0" class="d-flex flex-wrap">
          <v-card v-for="(image) in item.images" :key="image.id" class="mr-1 mb-1">
            <v-img
              :src="image.image | imageUrl('medium', false)"
              height="350"
              max-width="350"
              class="rounded"
            >
            </v-img>
            <v-card-actions>
              <v-btn
                :color="image.isMain?'warning':'normal'"
                text
                x-small
                icon
                @click="setItemImageMain(image)"
              >
                <v-icon v-if="image.isMain">mdi-bookmark</v-icon>
                <v-icon v-else>mdi-bookmark-outline</v-icon>
              </v-btn>

              <v-spacer></v-spacer>

              <v-btn
                text
                x-small
                icon
                @click="deleteItemImage(image)"
              >
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
        <div v-else class="">
          Пока ничего нет
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$router.go(-1)">Назад</v-btn>
        <v-btn @click="showDialogUploadImage=true">Загрузить</v-btn>
      </v-card-actions>
    </v-card>
    <my-dialog-upload-item-image2 v-model="showDialogUploadImage" :on-submit="onUploadImage"/>
    <v-overlay :value="busy">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { dashboardPageCommonAttributes } from '@/mixins/DashboardPageCommonAttributes'
import { mapActions, mapMutations, mapState } from 'vuex'
import ToastService from '@/service/ToastService'
import { ruleRequired } from '@/utils/FormRules'
import ItemUpdateForm from '@/model/item/ItemUpdateForm'
// import MyDialogUploadItemImage from '@/components/ui/dialog/MyDialogUploadItemImage.vue'
import MyDialogUploadItemImage2 from '@/components/ui/dialog/MyDialogUploadItemImage2.vue'

export default {
  ...dashboardPageCommonAttributes,
  name: 'OrderLineItemEditPage',
  components: { MyDialogUploadItemImage2 },
  props: {
    itemId: {
      type: [String],
      required: true
    },
    orderId: {
      type: [String],
      required: true
    }
  },
  data() {
    return {
      showDialogUploadImage: false,
      form: {
        valid: true,
        data: {
          name: null,
          content: null
        },
        rule: {
          name: [ruleRequired()],
          content: []
        }
      }
    }
  },
  computed: {
    ...mapState('order_line_item_edit', ['item','busy'])
  },
  created() {
    this.setItemId({ itemId: this.itemId })
    this.setOrderId({ orderId: this.orderId })
    this.fetchData()
      .then(() => {
        this.form.data.name = this.item.name
        this.form.data.content = this.item.content
      })
      .catch((reason) => {
        console.warn(reason)
        ToastService.error('Строка не найдена')
      })
  },
  methods: {
    ...mapMutations('order_line_item_edit', {
      setOrderId: 'SET_ORDER_ID',
      setItemId: 'SET_ITEM_ID'
    }),
    ...mapActions('order_line_item_edit', ['fetchData','updateItem','uploadImage','deleteImages','setMainImage']),
    submitForm() {
      const form = {
        ... ItemUpdateForm.create(this.item),
        name: this.form.data.name,
        content: this.form.data.content
      }

      this.updateItem({ form })
        .then(() => {
          ToastService.success('Данные сохранены')
          this.$router.go(-1)
        })
        .catch((error) => {
          console.warn(error)
          ToastService.error('Что-то пошло не так')
        })
    },
    onUploadImage(blobImage) {
      if (blobImage.size > 20000000) {
        ToastService.error('Выбранная область более 20Mb, выберите меньше')
        throw Error()
      }
      // console.log(blobImage)

      this.uploadImage({
        file: blobImage
      })
        .then(() => {
          ToastService.success('Картинка закачана')
          this.showDialogUploadImage = false
        })
        .catch((error) => {
          console.warn(error)
          ToastService.error('Что-то пошло не так')
        })
    },
    deleteItemImage(image) {
      this.deleteImages({
        itemImageIds: [image.id]
      }).then((result) => {
        ToastService.success('Картинки удалены')
      }).catch((error) => {
        ToastService.error('Что-то пошло не так!')
        console.log(error)
      })
    },
    setItemImageMain(image) {
      this.setMainImage({
        itemImageId: image.id
      }).then((result) => {
        ToastService.success('Главная изображение установлено')
      }).catch((error) => {
        ToastService.error('Что-то пошло не так!')
        console.log(error)
      })
    }
  },
  head: {
    title: function () {
      return {
        inner: 'Изменить товар'
      }
    }
  }
}
</script>
